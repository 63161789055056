/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



// // ====================== map =====================================
// function initMap(){
//   var element = document.getElementById('map');
//   var element2 = document.getElementById('map2');
//   var options = {
//     zoom: 17,
//     center:{
//       lat:48.944199,
//       lng:24.704301
//     }
//   }; 
//   var options2 = {
//     zoom: 12,
//     center:{
//       lat:49.944199,
//       lng:26.704301
//     }
//   }; 



//   var myMap = new google.maps.Map(element, options);
//   var myMap2 = new google.maps.Map(element2, options2);

//   var markers = [

//   {
//     coordinates:{
//       lat:48.944199,
//       lng:24.704301
//     },
//     // image:"assets/images/map-marker.png",
//     // info:"<img src='dist/images/molfar_logo_main-svg.svg'>"
//   },{
//     coordinates:{
//       lat:49.944199,
//       lng:26.704301
//     },
//     // image:"assets/images/adv_1.svg",
//     // info:"<img src='dist/images/molfar_logo_main-svg.svg'>"
//   }
//   ];
  

  
//   function addMarker(properties){
//     var marker = new google.maps.Marker({
//       position: properties.coordinates,
//       map: myMap,
//       icon:properties.image
//     });
//     var marker2 = new google.maps.Marker({
//       position: properties.coordinates,
//       map: myMap2,
//       icon:properties.image
//     });
//     if(properties.image){
//       marker.setIcon(properties.image);
//     }
//     if(properties.info){
//       marker.addListener('click',function(){
//         InfoWindow.open(myMap, marker);
//       });
//       var InfoWindow = new google.maps.InfoWindow({
//         content:properties.info
//       });
//     }
//   }
//     for(var i = 0; i < markers.length; i++){
//     addMarker(markers[i]);
//   }
// }

// ====================== end map =====================================



// ====================== smooth scroll anchor ================

// jQuery(function() {
  //   jQuery('.scrolldown').click(function() {
    //     if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
      //       var target = jQuery(this.hash);
      //       target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
      //       if (target.length) {
        //         jQuery('html,body').animate({
          //           scrollTop: target.offset().top - 80
          //         }, 1000);
          //         return false;
          //       } 
          //     }
          //   });
          // });

          // ====================== end smooth scroll anchor ================ 



jQuery(document).ready(function(){      // global document.ready


  // initMap();
              // var animateButton = function(e) {

              //   e.preventDefault();
              //   //reset animation
              //   e.target.classList.remove('animate');
                
              //   e.target.classList.add('animate');
                
              //   e.target.classList.add('animate');
              //   setTimeout(function(){
              //     e.target.classList.remove('animate');
              //   },6000);
              // };

              // var classname = document.getElementsByClassName("button");

              // for (var i = 0; i < classname.length; i++) {
              //   classname[i].addEventListener('click', animateButton, false);
              // }

              // jQuery('.button').click(function(e){
              //   e.preventDefault();
              //   // jQuery(this).removeClass('animate');
              //   jQuery(this).addClass('animate');
              //   setTimeout(function(){
              //    jQuery(this).removeClass('animate');
              //   },6000);
              // })
 



  jQuery('#header-leng-select').niceSelect();


 

  jQuery('.popup-btn').fancybox({
     touch: false
  });
  jQuery('.download-popup-form').fancybox({
    touch: false
  });



  jQuery('.homeSlider').slick({
    slidesToShow: 1,
    arrows:false,
    speed:1500,
    dots:true,
    fade:true,
    dotsClass: 'slick-control',

  });


  jQuery(".projects-slider").slick({
    adaptiveHeight:true,
    nextArrow: jQuery('.prSl-Next'),
    prevArrow: jQuery('.prSl-Prev'),
  });

  jQuery('[data-triger]').click(function(){
    var collection =  jQuery(this).data('collection');
    var triger = jQuery(this).data('triger');
    jQuery('[data-collection="'+collection+'"][data-triger]').removeClass('tab-active');
    jQuery(this).addClass('tab-active');
    jQuery('[data-collection="'+collection+'"]').removeClass('active');
    jQuery('[data-collection="'+collection+'"][data-target="'+triger+'"]').addClass('active');
     var hash = jQuery(this).attr('id');

     if(history.pushState) {
        history.pushState(null, null, '#'+hash);
      }
      else {
          location.hash = hash;
      }
  });
    

    jQuery('.blog-items').slick({
        slidesToShow: 3,
        arrows:false,
        responsive: [
            {
                breakpoint:1023,
                settings: {
                    slidesToShow: 2,
                    dots:true,
                    dotsClass: 'blog-slick-control',
                }
            },
            {
                breakpoint:640,
                settings: {
                    slidesToShow: 1,
                    dots:true,
                    dotsClass: 'blog-slick-control',
                }
            },

        ]
    });


  jQuery( ".icon" ).click(function(){
    jQuery(this).toggleClass('active');
    jQuery('.header__menu').toggleClass('active');
    jQuery('body').toggleClass('hiddenScroll');
  });





  jQuery(document).mouseup(function (e) {
    var container = jQuery(".header__menu");
    if (container.has(e.target).length === 0){
      container.removeClass('active');
      jQuery( ".icon" ).removeClass('active');
      jQuery('body').removeClass('hiddenScroll');
    }
  });

  
  if (window.location.hash.length>1)
   {
    setTimeout(function(){
      jQuery(window.location.hash).trigger('click');

    }, 1);

  }

   




  jQuery('.bg').height(jQuery('.bg').width()/2-120);

  jQuery(window).resize(function() {
   jQuery('.bg').height(jQuery('.bg').width()/2-120);
 });

  jQuery(window).scroll(function() {
    if (jQuery(this).scrollTop() > 10){  
      jQuery("header").addClass('fixed');
      jQuery( ".icon" ).addClass('fixed');
    }
    else  {
      jQuery("header").removeClass('fixed');
      jQuery( ".icon" ).removeClass('fixed');
    }
  });

  jQuery('.blogPage__btn a').click(function(e){
    e.preventDefault();
    jQuery('.rotate-romb').addClass('loading');
  });


  ///////////////////////////
  // jQuery(".move-area").mousemove(function(event) {
  //   var eye = jQuery(".eye");
  //   var x = (eye.offset().left) + (eye.width() / 2);
  //   var y = (eye.offset().top) + (eye.height() / 2);
  //   var rad = Math.atan2(event.pageX - x, event.pageY - y);
  //   var rot = (rad * (180 / Math.PI) * -1) + 180;
  //   eye.css({
  //     '-webkit-transform': 'rotate(' + rot + 'deg)',
  //     '-moz-transform': 'rotate(' + rot + 'deg)',
  //     '-ms-transform': 'rotate(' + rot + 'deg)',
  //     'transform': 'rotate(' + rot + 'deg)'
  //   });
  // });
  // jQuery(document).keydown(function(e){
  //   if (e.keyCode ==222) {
  //     jQuery('.move-area').css({'display':'block'})     
  //   } 
  // });
  // jQuery(document).keyup(function(e){
  //   if (e.keyCode ==222) {
  //     jQuery('.move-area').css({'display':'none'})     
  //   }



  // });



 


  // Open download form if click on download link
  if ( typeof download_button_hook === 'undefined') { 

    jQuery('.download-form-open').on('click', function(e){
     var link=jQuery(this).attr('href');
     console.log(link);
     jQuery('#download_form_link').val(link);
     jQuery.fancybox.open({ // FancyBox 3
      src: '#form-download', 
      // modal: true
    });
     e.preventDefault();   

   });

    download_button_hook=true;
  }






});


















jQuery(document).ready(function(){  
 // wordpress admin panel 
 jQuery('html').attr('style', 'margin-top: 0!important');
 jQuery('#wpadminbar').addClass('overflow');
 var hide;
 jQuery('#wpadminbar').on('mouseover', function(){
  setTimeout(function(){
    jQuery('#wpadminbar').removeClass('overflow');
  },1000);
  if(!jQuery('#wpadminbar').hasClass('open')){
    jQuery('#wpadminbar').addClass('open overflow');
  } else{
    clearTimeout(hide);
  }
});
 jQuery('#wpadminbar').on('mouseleave', function(){
  hide = setTimeout(function(){
    jQuery('#wpadminbar').addClass('overflow');
    jQuery('#wpadminbar').removeClass('open');
  },2000);
});
 // end wordpress admin panel
});






